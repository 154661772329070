<div>
  <img [src]="config?.logoImageURL"/>
  
  <p>
    <strong>Cookies Required</strong>
    <br/><br/>
    It appears that the site cannot load because cookies are fully disabled. To ensure the best experience on our website, please enable cookies in your browser settings.
    Thank you for your understanding and cooperation.
  </p>

  <small>
    For any questions or concerns, please contact our <a href="mailto:{{ config?.supportEmail }}">support team</a>.
  </small>
</div>