import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SsoClientPublicConfig } from 'src/app/model/sso-client';
import { SsoUser } from 'src/app/model/sso-user';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-initial',
  templateUrl: './header-initial.component.html',
  styleUrls: ['./header-initial.component.scss'],
})
export class HeaderInitialComponent implements OnInit {
  user: SsoUser;
  showProfileMenu: boolean = false;
  navMenu = {
    open: false,
    items: [
      {
        label: 'My Competitions',
        link: `${this.currentClientService.routePrefix}/user/my-apps`,
        icon: 'contact_page',
        expandable: false,
      },
      {
        label: 'Users',
        link: `${this.currentClientService.routePrefix}/admin/user-management`,
        icon: 'recent_actors',
        expandable: false,
      },
      {
        label: 'Apps Management',
        link: `${this.currentClientService.routePrefix}/admin/apps-management`,
        icon: 'apps',
        expandable: false,
      },
      {
        label: 'System Log',
        link: `${this.currentClientService.routePrefix}/admin/system-log`,
        icon: 'dns',
        expandable: false,
      },
      {
        label: 'Notifications',
        expanded: false,
        expandable: true,
        icon: 'notifications',
        items: [
          {
            label: 'Email Templates',
            link: `${this.currentClientService.routePrefix}/admin/email-templates`,
          },
          {
            label: 'In App',
            link: `${this.currentClientService.routePrefix}/user/todo`,
          },
        ],
      },
    ],
  };

  branding: SsoClientPublicConfig = {
    id: '',
    name: '',
    baseUrl: '',
    privacyPolicyURL: '',
    termsURL: '',
    faviconImageURL: '',
    logoImageURL: '',
    logoAltTag: '',
    emailSignatureURL: '',
    supportEmail: '',
    emailSender: '',
  };

  constructor(
    public currentUserService: CurrentUserService,
    public currentClientService: CurrentClientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUserService.user$.subscribe((ssoUser) => {
      this.user = ssoUser;
    });
    this.getBranding();
    this.navMenu.items
      .filter((i) => i.expandable)
      .forEach((i) => {
        i.expanded = i.items.filter((c) => this.router.url.includes(c.link)).length > 0;
      });
  }

  async getBranding() {
    this.branding = await lastValueFrom(
      this.currentClientService.publicClientConfig$
    );
  }

  logout() {
    this.currentUserService.logout();
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  closeProfileMenu() {
    this.showProfileMenu = false;
  }

  toggleNavMenu() {
    this.navMenu.open = !this.navMenu.open;
  }

  closeNavMenu() {
    this.navMenu.open = false;
    this.navMenu.items
      .filter((item) => item.expandable)
      .forEach((item) => (item.expanded = false));
  }
}
