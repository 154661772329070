import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CurrentClientService } from 'src/app/services/current-client.service';
import * as CookieConsent from 'vanilla-cookieconsent';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(public currentClientService: CurrentClientService) {}

  async init() {
    const clientConfig = await lastValueFrom(
      this.currentClientService.publicClientConfig$
    );
    return CookieConsent.run({
      cookie: {
        domain: /(?<=\.).+/.exec(location.hostname)?.[0] || '',
      },
      guiOptions: {
        consentModal: {
          layout: 'box',
          position: 'bottom left',
          flipButtons: false,
          equalWeightButtons: false,
        },
        preferencesModal: {
          layout: 'box',
          flipButtons: false,
          equalWeightButtons: false,
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Cookies',
              description: `We store cookies on your device to ensure that CarrotID functions properly. These cookies are essential for authorization and authentication purposes and cannot be disabled. Read our <a href="${clientConfig?.privacyPolicyURL}" target="_blank">Cookie Policy & Annex.</a>`,
              acceptAllBtn: 'I understand',
              acceptNecessaryBtn: '',
            },
            preferencesModal: {
              title: 'Cookie Preferences Manager',
              acceptAllBtn: 'I understand',
              acceptNecessaryBtn: '',
              savePreferencesBtn: '',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  description: `We store cookies on your device to ensure that CarrotID functions properly. These cookies are essential for authorization and authentication purposes and cannot be disabled. Read our <a href="${clientConfig?.privacyPolicyURL}" target="_blank">Cookie Policy & Annex.</a>`,
                },
                {
                  title: 'Essential (always enabled)',
                  description:
                    'These cookies ensure that our website functions properly.',
                  linkedCategory: 'necessary',
                },
              ],
            },
          },
        },
      },
    });
  }

  showPreferences() {
    CookieConsent.showPreferences();
  }
}
