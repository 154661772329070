import { Component, OnInit } from "@angular/core";
import { CurrentClientService } from "../services/current-client.service";
import { TabTitleService } from "../shared/services/tab-title.service";
import { firstValueFrom } from "rxjs";
import { SsoClientPublicConfig } from "../model/sso-client";

@Component({
  selector: "cookies-error",
  templateUrl: "./cookies-error.component.html",
  styleUrls: ["./cookies-error.component.scss"],
})
export class CookiesErrorComponent implements OnInit {

  config: SsoClientPublicConfig = null;

  constructor(
    public currentClientService: CurrentClientService,
    private tabTitleService: TabTitleService
  ) {}


  async ngOnInit() {
    this.currentClientService.clearInitialRoute();
    this.tabTitleService.setTabTitle('Cookies Required');
    this.config = await firstValueFrom(this.currentClientService.publicClientConfig$);
  }
}
