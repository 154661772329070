<div class="container">
  <div class="footer">
    <div class="logo">
      <a href="https://carrot.net/" target="_blank">
        <img id="footer-logo" src="assets/images/carrot-logo.png" width="129" alt="Carrot Logo" class="footer-logo">
      </a>
      Where brilliant ideas come <br> to life through incentives.
    </div>
    <div class="socials">
      <a href="https://www.facebook.com/carrotnet" target="_blank">
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.9998 0C4.47711 0 0 4.49365 0 10.0367C0 14.7436 3.22874 18.6932 7.58425 19.778V13.104H5.52229V10.0367H7.58425V8.7151C7.58425
            5.29899 9.12462 3.7156 12.4662 3.7156C13.0997 3.7156 14.1929 3.84046 14.6401 3.96491V6.74509C14.4041 6.7202 13.9941 6.70775 13.4849
            6.70775C11.8454 6.70775 11.2118 7.33123 11.2118 8.95196V10.0367H14.4781L13.9169 13.104H11.2118V20C16.1633 19.3998 20 15.1683 20
            10.0367C19.9996 4.49365 15.5225 0 9.9998 0Z"/>
        </svg>
      </a>
      <a href="https://www.linkedin.com/company/carrotincentives/" target="_blank">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 455 455" xml:space="preserve"
        >
          <g>
            <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"/>
            <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M0,0v455h455V0H0z M141.522,378.002H74.016V174.906h67.506V378.002z
              M107.769,147.186h-0.446C84.678,147.186,70,131.585,70,112.085c0-19.928,15.107-35.087,38.211-35.087
              c23.109,0,37.31,15.159,37.752,35.087C145.963,131.585,131.32,147.186,107.769,147.186z M385,378.002h-67.524V269.345
              c0-27.291-9.756-45.92-34.195-45.92c-18.664,0-29.755,12.543-34.641,24.693c-1.776,4.34-2.24,10.373-2.24,16.459v113.426h-67.537
              c0,0,0.905-184.043,0-203.096H246.4v28.779c8.973-13.807,24.986-33.547,60.856-33.547c44.437,0,77.744,29.02,77.744,91.398V378.002
              z"/>
          </g>
        </svg>
      </a> 
      <a href="https://www.youtube.com/carrotnet" target="_blank">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 310 310" xml:space="preserve">
          <g id="XMLID_822_">
            <path id="XMLID_823_" d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938
              C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527
              C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991
              c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764
              c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861
              C204.394,157.263,202.325,160.684,199.021,162.41z"/>
          </g>
        </svg>
      </a>
    </div>
    <div class="divider"></div>
    <div class="rights">
      <span>Powered by <a href="https://www.carrot.net" target="_blank">Carrot</a>. © {{year}}. All rights reserved.</span>
    </div>
    <div class="links">
      <a *ngIf="branding?.privacyPolicyURL" [href]="branding?.privacyPolicyURL" target="_blank">Privacy Policy</a>
      <a (click)="showCookiePreferences()" (keydown.enter)="showCookiePreferences()" tabindex="0">Cookie Preferences</a>
      <a *ngIf="branding?.supportEmail" [href]="'mailto:' + branding?.supportEmail" target="_blank">Help</a>
    </div>
  </div>
</div>
